import React from 'react';
import { Button } from "react-bootstrap";

const ButtonCard = (props: {
  name: string,
  bColor: string,
  bgColor: string,
  fColor: string
}) => {
  return (
    <Button
      size="lg"
      variant="primary"
      active
      style={{
        borderColor: props.bColor,
        backgroundColor: props.bgColor,
        fontWeight: "bold",
        marginRight: "10px",
        marginBottom: "10px",
        color: props.fColor,
        textTransform: "capitalize",
      }}>
      {props.name}
    </Button>
  );
}

export default ButtonCard;