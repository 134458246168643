import React from 'react';
import { FaCog, FaConciergeBell, FaLightbulb } from "react-icons/fa";

const ServiceItem = (props: {
    title: string,
    description: string;
    icon: string,
    iColor: string,
    bgColor: string
}) => {
    return (
        <>
            <div style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                marginBottom: "10px"
            }}>
                <div style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                    lineHeight: "100px",
                    backgroundColor: props.bgColor,
                    width: "100px",
                    height: "100px",
                    borderRadius: "20px",
                    border: "1px solid rgba(0,0,0,.125)"
                }}>
                    {renderIcon(props.icon, props.iColor)}
                </div>
            </div>
            <p>{props.title}</p>
            <hr />
            <p style={{ color: "#6c757d" }}>{props.description}</p>
        </>
    );
}

const renderIcon = (icon: string, iColor: string) => {
    if (icon === "Support")
        return (<FaCog size="35" color={iColor} />);
    else if (icon === "On-Demand")
        return (<FaConciergeBell size="35" color={iColor} />);
    else
        return (<FaLightbulb size="35" color={iColor} />);
};

export default ServiceItem;