import React from 'react';
import Container from 'react-bootstrap/Container';
import HeaderSection from './sections/Header';
import ServicesSection from './sections/Services';
import FooterSection from './sections/Footer';
import ProductsSection from './sections/Products';

const App: React.FC = () => {
  return (
    <Container id="home" fluid style={{ padding: 0 }}>
      <HeaderSection />
      <ServicesSection id="services" />
      <ProductsSection id="products" />
      <FooterSection id="contact" />
    </Container>
  );
};

export default App;
