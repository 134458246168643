import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

const FooterSection = (props: { id: string }) => {
  const date: Date = new Date();

  return (
    <Container fluid
      id={props.id}
      style={{
        backgroundColor: "#199490",
        textAlign: "center",
        verticalAlign: "middle",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}>
      <Row>
        <Col sm style={{ color: "#ffffff" }}>
          <p >&copy; 2017 - {date.getFullYear()} &#9829; Recovecko Technologies. All rights reserved. </p>
          <hr />
          <a style={{ color: "#323648" }}
            title="Recovecko Technologies"
            href="mailto:support@recovecko.com">
            support@recovecko.com
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default FooterSection;