import React from 'react';
import { Jumbotron } from "react-bootstrap";
import ButtonCard from "../components/ButtonCard";

const HeaderCard = () => {
  return (
    <Jumbotron
      className="text-center"
      style={{
        backgroundColor: "#E9ECEF",
        marginTop: "55px",
        marginBottom: "0px",
        borderRadius: "0px" 
      }}>
      <h1 style={{ color: "#199490", fontWeight: "bold", textTransform: "capitalize" }}>Passion move us</h1>
      <p style={{ color: "#323648" }}>
        We design and implement software on-demand using cutting-edge technologies <br /> to meet your needs.
      </p>
      <p style={{ marginTop: "50px" }}>
        <a href="#services">
          <ButtonCard name="Let's know more about us" bColor="#199490" bgColor="#199490" fColor="#fff" />
        </a>
      </p>
    </Jumbotron >
  );
}

export default HeaderCard;