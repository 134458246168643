import React from 'react';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FaGooglePlay, FaApple, FaChrome } from "react-icons/fa";

const IconButtonCard = (props: {
  name: string,
  bColor: string,
  bgColor: string,
  fColor: string
}) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={popover(props.name)}>
      <Button
        size="lg"
        variant="primary"
        active
        style={{
          borderColor: props.bColor,
          backgroundColor: props.bgColor,
          fontWeight: "bold",
          marginRight: "10px",
          marginBottom: "10px",
          color: props.fColor,
        }}>
        {renderIcon(props.name)} {' '}
        {props.name}
      </Button>
    </OverlayTrigger>
  );
}

const renderIcon = (name: string) => {
  if (name === "Google Play")
    return (<FaGooglePlay />);
  else if (name === "App Store")
    return (<FaApple />);
  else
    return (<FaChrome />);
};

const popover = (name: string) => {
  return (
    <Popover id="popover-basic">
      <Popover.Content>
        It is still not available in <strong>{name} </strong>
      </Popover.Content>
    </Popover>
  );
}

export default IconButtonCard;