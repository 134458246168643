import React from 'react';
import { Figure } from "react-bootstrap";

const FigureItem = (props: { description: string, image: string }) => {
    return (
        <Figure style={{
            padding: "15px",
        }}>
            <Figure.Image rounded style={{
                borderRadius: "5px",
            }}
                width={171}
                height={180}
                alt="171x180"
                src={props.image}
            />
            <hr />

            <Figure.Caption>
                {props.description}
            </Figure.Caption>
        </Figure>
    );
}

export default FigureItem;