import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import ServiceItem from "../components/ServiceItem";

const ServicesSection = (props: { id: string }) => {
    return (
        <Card
            id={props.id}
            className="text-center"
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "0px",
                borderWidth: "0px",
                marginBottom: "0px"
            }}>
            <Card.Body>
                <Card.Title style={{ color: "#323648" }}>Services</Card.Title>
                <Card.Text>
                    Recovecko Technologies focuses on customer needs. Our products and services <br /> use cutting-edge technologies.
                </Card.Text>
                <Container style={{
                    marginTop: "20px",
                    backgroundColor: "#ffffff"
                }}>
                    <Row>
                        <Col sm style={{ marginBottom: "15px" }}>
                            <ServiceItem
                                title="Technological Support"
                                description="Choosing the appropriate technologies and agile development for your company."
                                icon="Support"
                                iColor="#ffffff"
                                bgColor="#199490" />
                        </Col>
                        <Col sm style={{ marginBottom: "15px" }}>
                            <ServiceItem
                                title="On-demand Solutions"
                                description="Implementing the solution your business requires."
                                icon="On-Demand"
                                iColor="#323648"
                                bgColor="#E8CA3A" />
                        </Col>
                        <Col sm style={{ marginBottom: "15px" }}>
                            <ServiceItem
                                title="Financial Investments"
                                description="Providing tools and knowledge to make correct investment decisions."
                                icon="Idea"
                                iColor="#199490"
                                bgColor="#ffffff" />
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
}

export default ServicesSection;