import React from 'react';
import { Nav, Navbar, } from "react-bootstrap";

const HeaderNavbar = () => {
  return (
    <>
      <style type="text/css">
        {`
          .bg-light-v2 {
             background-color: #199490;
           }
        `}
      </style>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="light-v2"
        variant="light"
      >
        <Navbar.Brand
          href="#home"
          style={{ color: "#fff", fontWeight: "bold" }}>
          <img
            alt="Recovecko Technologies"
            src="/images/recovecko-small-green-icon.png"
            width="30"
            height="30"
            style={{backgroundColor: "#199490", borderRadius: "5px"}}
            className="d-inline-block align-top"
          />{' '}
          Recovecko
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ backgroundColor: "#fff", padding:"5px" }} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#services" style={{ color: "#fff" }}>Services</Nav.Link>
            <Nav.Link href="#products" style={{ color: "#fff" }}>Products</Nav.Link>
            <Nav.Link href="#contact" style={{ color: "#fff" }}>Contact</Nav.Link>            
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default HeaderNavbar;