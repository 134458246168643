import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import FigureItem from "../components/FigureItem";
import IconButtonCard from '../components/IconButtonCard';

const ProductsSection = (props: { id: string }) => {
    return (
        <Card
            id={props.id}
            className="text-center"
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "0px",
                marginBottom: "0px",
                borderWidth: "0px"
            }}>
            <Card.Body >
                <Card.Title style={{ color: "#323648", marginBottom: "20px" }}>Products</Card.Title>
                <Container style={{ backgroundColor: "#E9ECEF", padding: "20px", borderRadius: "10px" }}>
                    <Card.Subtitle style={{ color: "#323648", marginBottom: "20px" }}>Stock Screener</Card.Subtitle>
                    <Card.Text>
                        Discover the best matches based on your investment strategy. Make the right decision by looking at the financial result along with other key data. <br /> Also keep track of your favorite businesses by adding them to the watch list.
                    </Card.Text>
                    <Row>
                        <Col sm className="text-center" style={{ marginBottom: "15px" }}>

                            <FigureItem
                                description="Use existing screens or create your own, depending on your investment strategy."
                                image="images/app-1.jpg"
                            />
                        </Col>
                        <Col sm className="text-center" style={{ marginBottom: "15px" }}>
                            <FigureItem
                                description="Navigate through the result of the screen."
                                image="images/app-2.jpg"
                            />
                        </Col>
                        <Col sm className="text-center" style={{ marginBottom: "15px" }}>
                            <FigureItem
                                description="Review the financial result along with other key information."
                                image="images/app-3.jpg"
                            />
                        </Col>
                    </Row>

                    <Container>
                        <Row>
                            <Col sm >
                                <IconButtonCard name="Google Play" bColor="#199490" bgColor="#199490" fColor="#ffffff" />
                                <IconButtonCard name="App Store" bColor="#E8CA3A" bgColor="#E8CA3A" fColor="#323648" />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </Card.Body>
        </Card>
    );
}

export default ProductsSection;