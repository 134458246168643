import React from 'react';
import HeaderCard from "./HeaderCard";
import HeaderNavbar from "./HeaderNavbar";

const HeaderSection = () => {
    return (
        <>
            <HeaderNavbar />
            <HeaderCard />
        </>
    );
}

export default HeaderSection;